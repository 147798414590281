import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ServicesPageTemplate = ({ title, content, preamble, image, contentComponent }) => {
  const PageContent = contentComponent || Content
  const mainImage = getImage(image)

  return (
    <section className="section section--gradient content-page">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <div className="preamble">
                <p>{preamble}</p>
              </div>
              {image ? (
                <div className="columns">
                  <div className="column is-10 -is-offset-1">
                    <GatsbyImage image={mainImage} alt={title} />
                  </div>
                </div>
              ) : null}
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ServicesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ServicesPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageTitle={post.frontmatter.title} pageKeywords={post.frontmatter.seokeywords} pageDescription={post.frontmatter.preamble}>
      <ServicesPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        preamble={post.frontmatter.preamble}
        image={post.frontmatter.image}
      />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicesPage

export const servicesPageQuery = graphql`query ServicesPage {
  markdownRemark(frontmatter: {templateKey: {eq: "services-page"}}) {
    html
    frontmatter {
      seokeywords
      title
      preamble
      image {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
}
`
